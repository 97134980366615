import React from 'react'
import BaseCommandPage from './index'

class AlexaCommandPage extends React.Component {
  render() {
    return (
      <BaseCommandPage initialTabIndex={1} location={this.props.location} />
    )
  }
}

export default AlexaCommandPage

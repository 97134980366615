import React from 'react'
import { navigate } from 'gatsby'
import Layout from '../../Layouts/index'
import { kotiTitleGenerator } from '../../utils'
import _ from 'lodash'
import SEO from '../../components/SEO'

import './index.scss'

class CommandPage extends React.Component {
  ID_GOOGLE_HOME = 'google'
  ID_ALEXA = 'alexa'

  platforms = [
    {
      id: this.ID_GOOGLE_HOME,
      name: 'Google Home',
      wakeWord: (
        <>
          <b>“Hey Google”</b> or <b>“Ok Google”</b>
        </>
      ),
      url: '/commands/google',
    },
    {
      id: this.ID_ALEXA,
      name: 'Amazon Alexa',
      wakeWord: (
        <>
          <b>“Alexa”</b>
        </>
      ),
      url: '/commands/alexa',
    },
  ]

  commandData = [
    {
      title: 'Entertainment',
      sentences: [
        'Play cheerful songs on Spotify',
        { default: 'Play Toy Story on Netflix', [this.ID_ALEXA]: 'Play Netflix' },
        'Skip forward 30 seconds',
        { default: 'Play 10 minutes workout on Youtube', [this.ID_ALEXA]: 'Start Youtube'},
      ],
      opacity: 6,
      image: require('../../assets/img/koti-commands/headphones.jpg'),
    },
    {
      title: 'Interact with Home',
      sentences: [
        {default: 'Read building notices', [this.ID_ALEXA]: 'Ask Delight Homes to read building notices'},
        {default: 'Find restaurant offers', [this.ID_ALEXA]: 'Ask Delight Homes for restaurant recommendations'},
        {default: 'Read commands', [this.ID_ALEXA]: 'Ask Delight Homes to read commands'},
        {default: 'Talk to home buddy', [this.ID_ALEXA]: 'Start Delight Homes'},
      ],
      opacity: 6,
      image: require('../../assets/img/koti-commands/building.jpg'),
    },
    {
      title: 'Plan my day',
      sentences: [
        'Good Morning',
        'What’s the weather today',
        'Wake me up at 8 am tomorrow',
        'Set a 10 mins timer',
      ],
      opacity: 6,
      image: require('../../assets/img/koti-commands/coffee.jpg'),
    },
    {
      title: 'Ask me anything',
      sentences: [
        'Tell me Hong Kong news',
        'What is USD10 in HKD',
        'Set volume to 50%',
        'What can you do',
      ],
      opacity: 6,
      image: require('../../assets/img/koti-commands/anything.jpg'),
    },
  ]

  constructor(props) {
    super(props)
    this.state = {
      tabIndex: props.initialTabIndex || 0,
    }
  }

  onTabClick = index => {
    const path = _.get(this.platforms, `${index}.url`)
    navigate(path)
  }

  isDict(obj) {
    return obj.constructor === Object
  }

  render() {
    const tabsContainer = (
      <div className="tabs-container">
        {this.platforms.map((platform, index) => (
          <button 
            key={index}
            className={`button ${index === this.state.tabIndex ? 'active' : ''}`}
            onClick={() => this.onTabClick(index)} 
            onKeyPress={() => this.onTabClick(index)}
          > 
              {platform.name}
          </button>
        ))}
      </div>
    )

    const channelName = _.get(this.platforms, `${this.state.tabIndex}.id`)
    const cards = this.commandData.map((item, index) => (
      <div
        className="item"
        style={{
          background: `url('${item.image}')`,
          opacity: 1,
        }}
        key={index}
      >
        <h2 className="header-h3">{item.title}</h2>
        <p className="lead">
          {item.sentences.map((sentence, id) => {
            var finalSentence = sentence
            if (this.isDict(sentence)){
              finalSentence = _.get(sentence, channelName, _.get(sentence, 'default'))
            }
            return (
            <div className="command body-01-01" key={id}>
              “ {finalSentence} ”
            </div>
          )
          })}
        </p>
      </div>
    ))

    const mainContainer = (
      <>
        {tabsContainer}
        <section>
          <div className="heading-wrapper">
            <h1 className="header-h3">Start with</h1>
            <h1 className="header-h2">{_.get(this.platforms, `${this.state.tabIndex}.wakeWord`)}</h1>
          </div>

        </section>
        {cards}
      </>
    )

    return (
      <Layout location={this.props.location}>
        <SEO
          title={kotiTitleGenerator(
            `${_.get(this.platforms, `${this.state.tabIndex}.name`)} Commands`
          )}
          description="Delight is a Voice App that lets you access interesting services from your building and nearby. Works with Google Home, Amazon Alexa and Apple Siri."
        />
        <div className="page-commands">
          {mainContainer}
        </div>
      </Layout>
    )
  }
}

export default CommandPage
